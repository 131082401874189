// src/config/localeText.js

const localeText = {
  // Traduções básicas
  noRowsLabel: "Nenhuma linha",
  noResultsOverlayLabel: "Nenhum resultado encontrado.",
  errorOverlayDefaultLabel: "Ocorreu um erro.",

  // Toolbar
  toolbarDensity: "Densidade",
  toolbarDensityLabel: "Densidade",
  toolbarDensityCompact: "Compacto",
  toolbarDensityStandard: "Padrão",
  toolbarDensityComfortable: "Confortável",

  // Columns panel
  toolbarColumns: "Colunas",
  toolbarColumnsLabel: "Selecionar colunas",
  toolbarColumnsShowAll: "Mostrar todas",
  toolbarColumnsHideAll: "Esconder todas",

  // Filters panel
  toolbarFilters: "Filtros",
  toolbarFiltersLabel: "Mostrar filtros",
  toolbarFiltersTooltipHide: "Esconder filtros",
  toolbarFiltersTooltipShow: "Mostrar filtros",
  toolbarFiltersTooltipActive: (count) =>
    count !== 1 ? `${count} filtros ativos` : `${count} filtro ativo`,

  // Export panel
  toolbarExport: "Exportar", 
  toolbarExportLabel: "Exportar",
  toolbarExportCSV: "Baixar como CSV",
  toolbarExportPrint: "Imprimir",

  // Columns panel text
  columnsPanelTextFieldLabel: "Encontrar coluna",
  columnsPanelTextFieldPlaceholder: "Título da coluna",
  columnsPanelDragIconLabel: "Reordenar coluna",
  columnsPanelShowAllButton: "Mostrar todas",
  columnsPanelHideAllButton: "Esconder todas",

  // Filter panel text
  filterPanelAddFilter: "Adicionar filtro",
  filterPanelDeleteIconLabel: "Excluir",
  filterPanelLinkOperator: "Operador lógico",
  filterPanelOperators: "Operadores",
  filterPanelColumns: "Colunas",
  filterPanelOperator: "Operador",
  filterPanelValue: "Valor",
  filterPanelFilterValue: "Valor do filtro",

  // Filter operators text
  filterOperatorContains: "contém",
  filterOperatorEquals: "igual",
  filterOperatorStartsWith: "começa com",
  filterOperatorEndsWith: "termina com",
  filterOperatorIs: "é",
  filterOperatorNot: "não é",
  filterOperatorAfter: "está depois",
  filterOperatorOnOrAfter: "está em ou depois",
  filterOperatorBefore: "está antes",
  filterOperatorOnOrBefore: "está em ou antes",
  filterOperatorIsEmpty: "está vazio",
  filterOperatorIsNotEmpty: "não está vazio",

  // Header filter
  headerFilterLabel: "Mostrar filtros",

  // Column menu text
  columnMenuLabel: "Menu",
  columnMenuShowColumns: "Mostrar colunas",
  columnMenuFilter: "Filtrar",
  columnMenuHideColumn: "Esconder",
  columnMenuUnsort: "Desfazer ordenação",
  columnMenuSortAsc: "Ordenar ascendente",
  columnMenuSortDesc: "Ordenar descendente",

  // Footer text
  footerRowSelected: (count) =>
    count !== 1
      ? `${count.toLocaleString()} linhas selecionadas`
      : `${count.toLocaleString()} linha selecionada`,
  footerTotalRows: "Total de linhas:",
  footerTotalVisibleRows: (visibleCount, totalCount) =>
    `${visibleCount.toLocaleString()} de ${totalCount.toLocaleString()}`,

  // Pagination
  paginationLabelDisplayedRows: ({ from, to, count }) =>
    `${from}-${to} de ${count !== -1 ? count : `mais de ${to}`}`,
  paginationLabelRowsPerPage: "Linhas por página:",
  paginationFirstPageButton: "Primeira página",
  paginationLastPageButton: "Última página",
  paginationNextPageButton: "Próxima página",
  paginationPreviousPageButton: "Página anterior",
};

export default localeText;
