// Importação de Style
import style from "./header.module.css";

// Importação de Imagens
import logoSipremo from "../../images/SipremoSemFundopng.png";

function Header() {
  let user = JSON.parse(localStorage.getItem("user"));

  return (
    <div className={style["App"]}>
      {/* Imagem do header */}
      <div className={style["Image"]}>
        {/* eslint-disable-next-line jsx-a11y/alt-text */}
        <img src={logoSipremo} />
      </div>
      {/* Frase saudação */}
      <div className={style["saudacao"]}>Olá, {user.contact_info.name}</div>
    </div>
  );
}

export default Header;
