// Importação de React
import React, { useEffect, useState } from "react";

// importação de terceiros
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import OtpInput from "react-otp-input";

// Importação de Style
import style from "./recoverPass.module.css";

// Importação de Imagens
import mina from "../../images/mina.jpeg";
import logo from "../../images/logoLogin.jpg";

// Importação de Navegação
import { useNavigate } from "react-router-dom";

function RecoverPass() {
  // State de dados
  const [code, setCode] = useState("");
  const [email, setEMAIL] = useState("");
  const [codeInvited, setCodeInvited] = useState(false);
  const [codeConfirmed, setCodeConfirmed] = useState(false);

  // state de animação
  const [widthBar, setWidthBar] = useState(0);

  // constante de navegação
  const navigation = useNavigate();

  // Alteração do valor email
  const handleChangeEmail = (value) => {
    let variable = value.target.value;
    setEMAIL(variable);
  };

  // Alteração para tela do codigo
  const handleInviteCode = () => {
    setCodeInvited(true);
  };

  // Alteração de Confirmação do Código
  const handleConfirmCode = () => {
    setCodeConfirmed(true);

    setTimeout(() => {
      navigation("/");
    }, 10000);
  };

  // Animação de progress Bar
  useEffect(() => {
    if (codeConfirmed) {
      const interval = setInterval(() => {
        setWidthBar((prevWidth) => {
          if (prevWidth >= 100) {
            clearInterval(interval);
            return 100;
          }
          return prevWidth + 0.1;
        });
      }, 10);

      return () => clearInterval(interval);
    }
  }, [codeConfirmed]);

  return (
    <div className={style["App"]}>
      {/* Formulario de Recover Password */}
      <div className={style["Form"]}>
        {!codeInvited ? (
          <div className={style["Data-Login"]}>
            <div className={style["logo-div"]}>
              {/* eslint-disable-next-line jsx-a11y/alt-text */}
              <img src={logo} className={style["logo"]} />
            </div>

            {/* titulo da tela */}
            <div>
              <p className={style["Title"]}>Recuperar Senha</p>
            </div>

            {/* Input de email */}
            <Box
              component="form"
              sx={{
                "& > :not(style)": { width: "100%" },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                id="outlined-basic"
                label="Email"
                variant="outlined"
                onChange={(value) => {
                  handleChangeEmail(value);
                }}
              />
            </Box>

            {/* Botão de enviar código */}
            <button className={style["Button"]} onClick={handleInviteCode}>
              Enviar Código de Verificação
            </button>
          </div>
        ) : (
          <div className={style["Data-Login"]}>
            <div className={style["logo-div"]}>
              {/* eslint-disable-next-line jsx-a11y/alt-text */}
              <img src={logo} className={style["logo"]} />
            </div>
            {/* Titulo da tela */}
            <div>
              <p className={style["Title"]}>Recuperar Senha</p>
            </div>

            {/* Texto Informativo */}
            <div className={style["textInformation"]}>
              <p>Digite o Código de Confirmação</p>
            </div>

            {/* Campo de inserção de dados */}
            <OtpInput
              value={code}
              onChange={setCode}
              numInputs={6}
              renderInput={(props) => <input {...props} />}
              inputStyle={style["inputCode"]}
              containerStyle={style["containerInput"]}
            />

            {/* Botão de confirmar código */}
            <button className={style["Button"]} onClick={handleConfirmCode}>
              Confirmar Código
            </button>

            {/* Texto informativo com progress bar */}
            {codeConfirmed ? (
              <div>
                <p>Código Confirmado</p>
                <p>Redirecionando para realizar acesso</p>
                <p>
                  Redirecionamento em {10 - parseInt(widthBar / 10).toFixed(0)}{" "}
                  s
                </p>
                <div className="progress" style={{ marginTop: "2rem" }}>
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: `${widthBar}%` }}
                    aria-valuenow={widthBar}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            ) : null}
          </div>
        )}
      </div>

      {/* Div Image */}
      <div className={style["Image"]}>
        {/* eslint-disable-next-line jsx-a11y/alt-text */}
        <img src={mina} className={style["Image-Render"]} />
      </div>
    </div>
  );
}

export default RecoverPass;
