// Rotas.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Signin from '../pages/signin/signin';
import RecoverPass from '../pages/recoverPass/recoverPass';
import Home from '../pages/home/home';

function Rotas() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Signin />} />
        <Route path="/recoverPass" element={<RecoverPass />} />
        <Route path="/Home" element={<Home />} />
      </Routes>
    </Router>
  );
}

export default Rotas;