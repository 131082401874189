
import Rotas from './router/route';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {

  return <Rotas />;

}

export default App;
