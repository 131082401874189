// Importação do React
import { useState } from "react";

// importação de terceiros
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

// Importação de Style
import style from "./home.module.css";

// Importação de Imagens

// Importação de Component
import NavBarLeft from "../../components/navBarLeft/navBarLeft";
import Header from "../../components/header/header";
import BodyPeopleBody from "../../components/bodyPeopleBody/bodyPeopleBody";
import BodyReportBody from "../../components/bodyReportBody/bodyReportBody";
import BodyDataBody from "../../components/bodyDataBody/BodyDataBody";

function Home() {
  const [page, setPage] = useState("people");

  const handleChangePageType = (value) => {
    setPage(value);
  };

  return (
    <div className={style["App"]}>
      {/* Formulario de acesso */}
      <div className={style["menubar"]}>
        <NavBarLeft page={(value) => handleChangePageType(value)} />
      </div>

      {page === "people" ? (
        <div className={style["BodyHeader"]}>
          <div className={style["header"]}>
            <Header />
          </div>
          <div className={style["body"]}>
            <BodyPeopleBody />
          </div>
        </div>
      ) : page === "report" ? (
        <div className={style["BodyHeader"]}>
          <div className={style["header"]}>
            <Header />
          </div>
          <div className={style["body"]} >
            <BodyReportBody />
          </div>
        </div>
      ) : (
        <div className={style["BodyHeader-Data"]}>
          <div className={style["header"]}>
            <Header />
          </div>
          <div className={style["body-Data"]} >
            <BodyDataBody />
          </div>
        </div>
      )}
    </div>
  );
}

export default Home;
