// Importação do React
import { useState } from "react";

// Importação de Style
import style from "./navBarLeft.module.css";

// Importação de Imagens
import MenuIcon from "@mui/icons-material/Menu";
import PersonIcon from "@mui/icons-material/Person";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import LogoutIcon from "@mui/icons-material/Logout";
import DataSaverOffOutlinedIcon from '@mui/icons-material/DataSaverOffOutlined';

// Importação de navegação
import { useNavigate } from "react-router-dom";

function NavBarLeft({ page }) {
  const [typePage, setTypePage] = useState("people");

  // constante de navegação
  const navigation = useNavigate();

  // Navegação para tela inicial
  const handleChangePageToLogin = () => {
    navigation("/");
  };

  // Navegação para tela de gerenciamento de pessoas
  const handleChangePageToPerson = () => {
    page("people");
    setTypePage("people");
  };

  // Navegação para tela de relatorio
  const handleChangePageToReport = () => {
    page("report");
    setTypePage("report");
  };

  // Navegação para tela de relatorio
  const handleChangePageToData = () => {
    page("data");
    setTypePage("data");
  };

  return (
    <div className={style["App"]}>
      <div className={style["menuIcon"]}>
        <MenuIcon />
      </div>
      <div className={style["menuIconPersonContent"]}>
        <div
          className={
            typePage === "people"
              ? style["menuIconSelected"]
              : style["menuIconSelect"]
          }
          onClick={handleChangePageToPerson}
        >
          <PersonIcon />
        </div>
        <div
          className={
            typePage === "report"
              ? style["menuIconSelected"]
              : style["menuIconSelect"]
          }
          onClick={handleChangePageToReport}
        >
          <ContentPasteIcon />
        </div>
        <div
          className={
            typePage === "data"
              ? style["menuIconSelected"]
              : style["menuIconSelect"]
          }
          onClick={handleChangePageToData}
        >
          <DataSaverOffOutlinedIcon />
        </div>
      </div>
      <div className={style["menuIconExit"]} onClick={handleChangePageToLogin}>
        <LogoutIcon />
      </div>
    </div>
  );
}

export default NavBarLeft;
