const API_BASE_URL = "https://mcs.sipremo.com/api";
const LOCALHOST = "http://localhost:8000";

const API_ROUTES = {
  // Exemplo de rota com parâmetro dinâmico
  USER_BY_USERNAME: (username) =>
    `${API_BASE_URL}/user/user?username=${username}`,
  PEOPLE: `${API_BASE_URL}/user/all_users`,
  DELETE_PEOPLE: (username) =>
    `${API_BASE_URL}/user/delete_user?username=${username}`,
  ALTERNATE_PEOPLE: (username) =>
    `${API_BASE_URL}/user/alter_user?username=${username}`,
  CREATE_PEOPLE: `${API_BASE_URL}/user/create_user`,
  ALL_COMUNICATION: `${API_BASE_URL}/operacao/get_all_comunication`,
  GET_USERS_GROUP_BY_GROUP: `${API_BASE_URL}/operacao/get_all_users_response_for_group`,
  GET_REGISTRY_CONFIRMATION_PER_MINUTE: `${API_BASE_URL}/operacao/get_all_communication_registrys_for_ultimate_hour`,
};

export default API_ROUTES;
