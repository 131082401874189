import React from "react";

import style from "./informations.module.css";
import PersonIcon from "@mui/icons-material/Person";

function InformationDiv({ number, titleNumber }) {
  return (
    <div className={style["App"]}>
      <div className={style["number-users"]}>
        <text className={style["title"]}>{titleNumber}</text>
        <text className={style["number"]}>{number}</text>
        <div className={style["container-icon"]}>
          <PersonIcon className={style["icons"]} />
        </div>
      </div>
    </div>
  );
}

export default InformationDiv;
